import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
import { TextInput } from "../Input";
import { HelpTooltip } from "../HelpTooltip";
import { SuccessIndicator } from "../SuccessIndicator";
export var AlertSuppressionEditDialog = function (_a) {
    var suppression = _a.suppression, onClose = _a.onClose, onSubmit = _a.onSubmit, alertHistory = _a.alertHistory, onDelete = _a.onDelete;
    var user = useAuth0().user;
    var buttons = [
        {
            title: "30 minutes",
            value: 1000 * 60 * 30,
        },
        {
            title: "1 hour",
            value: 1000 * 60 * 60,
        },
        {
            title: "3 hours",
            value: 1000 * 60 * 60 * 3,
        },
        {
            title: "12 hours",
            value: 1000 * 60 * 60 * 12,
        },
        {
            title: "1 day",
            value: 1000 * 60 * 60 * 24,
        },
        {
            title: "7 days",
            value: 1000 * 60 * 60 * 24 * 7,
        },
    ];
    var expirationRef = useRef(null);
    var patternRef = useRef(null);
    var descriptionRef = useRef(null);
    var dateToInputString = function (date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0, 16);
    };
    var onButtonClick = function (value) {
        if (!expirationRef.current) {
            return;
        }
        var date = new Date();
        date.setMilliseconds(date.getMilliseconds() + value);
        expirationRef.current.value = dateToInputString(date);
    };
    var _b = useState(), errorMessage = _b[0], setErrorMessage = _b[1];
    var displayErrorMessage = function (message) {
        setErrorMessage(message);
        setTimeout(function () {
            setErrorMessage(undefined);
        }, 10000);
    };
    var filterAlertsByPattern = function (alerts, pattern) {
        return alertHistory
            .map(function (a) { return a.message.replace("[Suppressed by Command Console] ", ""); })
            .filter(function (a) { return a.includes(pattern); });
    };
    var _c = useState(suppression === null || suppression === void 0 ? void 0 : suppression.pattern), pattern = _c[0], setPattern = _c[1];
    var filteredAlerts = pattern
        ? filterAlertsByPattern(alertHistory, pattern)
        : [];
    var onSave = function () {
        var _a;
        if (!expirationRef.current ||
            !patternRef.current ||
            !descriptionRef.current) {
            return;
        }
        if (!expirationRef.current.value) {
            displayErrorMessage("Expiration must be set.");
            return;
        }
        if (!descriptionRef.current.value) {
            displayErrorMessage("Description must be set.");
            return;
        }
        var alertToCreate = {
            id: suppression ? suppression.id : 0,
            description: descriptionRef.current.value,
            pattern: patternRef.current.value ? patternRef.current.value : undefined,
            until: new Date(expirationRef.current.value),
            operator: (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : "",
        };
        setErrorMessage(undefined);
        onSubmit(alertToCreate);
        onClose();
    };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
        React.createElement("label", { className: "font-N5 text-white w-full text-center" }, "Edit Suppression"),
        React.createElement("div", { className: "grid grid-cols-2 gap-x-4 gap-y-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Pattern (optional):"),
            React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
                React.createElement(TextInput, { placeholder: "Enter pattern", ref: patternRef, onChange: function () { var _a; return setPattern((_a = patternRef.current) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: suppression === null || suppression === void 0 ? void 0 : suppression.pattern }),
                React.createElement(HelpTooltip, { text: "The pattern provided will match if the incoming alert contains the string as a substring of the title. Leave empty to match all alerts." })),
            pattern && (React.createElement("div", { className: "flex flex-col gap-y-2 col-span-2" },
                filteredAlerts.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("label", { className: "font-N8 text-white" }, "Recent Matches"),
                    filteredAlerts.map(function (f, i) {
                        return (React.createElement("label", { className: "font-N8p5 text-white", key: i }, f));
                    }))),
                filteredAlerts.length == 0 && (React.createElement("label", { className: "font-N8 text-white" }, "No recent alert matches.")))),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] col-span-2 mx-auto w-full" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Description:"),
            React.createElement(TextInput, { placeholder: "Provide helpful description", ref: descriptionRef, defaultValue: suppression === null || suppression === void 0 ? void 0 : suppression.description }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] col-span-2 mx-auto w-full" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Expiration:"),
            React.createElement(TextInput, { type: "datetime-local", ref: expirationRef, defaultValue: (suppression === null || suppression === void 0 ? void 0 : suppression.until)
                    ? dateToInputString(new Date(suppression.until))
                    : undefined }),
            React.createElement("div", { className: "flex flex-row flex-wrap col-span-2 gap-4 items-center" },
                React.createElement("label", { className: "font-N8 text-white" }, "Expiration Presets:"),
                buttons.map(function (b, i) { return (React.createElement(StyledButton, { key: i, text: b.title, onClick: function () { return onButtonClick(b.value); } })); })),
            React.createElement("div", { className: "flex flex-rowcol-span-2 gap-x-4 mt-16" },
                React.createElement(StyledButton, { text: "SAVE", style: StyledButtonStyle.Green, onClick: onSave }),
                onDelete != null && (React.createElement(StyledButton, { text: "DELETE", style: StyledButtonStyle.Green, onClick: function () {
                        onDelete();
                        onClose();
                    } })),
                React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: onClose }))),
        React.createElement(SuccessIndicator, { message: errorMessage, success: false })));
};
