import React from "react";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
export var ViewAllAlertsButton = function (_a) {
    var powernodeIds = _a.powernodeIds;
    var onViewAllAlerts = function () {
        var query = powernodeIds.join("%20OR%20");
        window.open("https://app.opsgenie.com/alert/list?query=".concat(query));
    };
    return (React.createElement(StyledButton, { style: StyledButtonStyle.Gray, text: "VIEW ALL", onClick: onViewAllAlerts }));
};
