"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSorting = exports.TransactionTrackingType = exports.EquipmentManufacturer = void 0;
var EquipmentManufacturer;
(function (EquipmentManufacturer) {
    EquipmentManufacturer["DELTA_ELECTRONICS"] = "Delta Electronics";
    EquipmentManufacturer["VOLKSWAGEN_GROUP_OF_AMERICA"] = "Volkswagen Group of America";
    EquipmentManufacturer["LG_INNOTEK"] = "LG Innotek";
    EquipmentManufacturer["TESTOP"] = "TESTOP";
    EquipmentManufacturer["DENSO"] = "Denso";
    EquipmentManufacturer["LEAR"] = "Lear";
    EquipmentManufacturer["RIVIAN"] = "Rivian";
    EquipmentManufacturer["CONTINENTAL"] = "Continental";
    EquipmentManufacturer["WE_CORPORATION"] = "We Corporation";
    EquipmentManufacturer["TESLA"] = "Tesla";
    EquipmentManufacturer["VISTEON"] = "Visteon";
    EquipmentManufacturer["LUCID"] = "Lucid Motors";
    EquipmentManufacturer["PORSCHE"] = "Porsche Cars North America";
    EquipmentManufacturer["BMW"] = "BMW AG";
    EquipmentManufacturer["POLESTAR"] = "Polestar Corp.";
    EquipmentManufacturer["FORD"] = "Ford Motor Company";
    EquipmentManufacturer["KOSTAL"] = "Leopold Kostal GmbH &Co. KG";
    EquipmentManufacturer["UNKNOWN"] = "Unknown";
})(EquipmentManufacturer = exports.EquipmentManufacturer || (exports.EquipmentManufacturer = {}));
/**
 * List of transaction tracking types
 */
var TransactionTrackingType;
(function (TransactionTrackingType) {
    TransactionTrackingType["ATYPICAL_TESTING"] = "Atypical Testing";
    TransactionTrackingType["COMMISSIONING"] = "Commissioning";
    TransactionTrackingType["MAINTENANCE_WINDOW"] = "Maintenance Window";
    TransactionTrackingType["FREE_PRICING_MAINTENANCE_WINDOW"] = "Free Price Maintenance Window";
})(TransactionTrackingType = exports.TransactionTrackingType || (exports.TransactionTrackingType = {}));
/**
 * Desired sorting for transaction list.
 */
var TransactionSorting;
(function (TransactionSorting) {
    TransactionSorting["ENERGY_DELIVERED"] = "ENERGY_DELIVERED";
    TransactionSorting["PEAK_POWER"] = "PEAK_POWER";
    TransactionSorting["SESSION_START"] = "SESSION_START";
    TransactionSorting["SESSION_END"] = "SESSION_END";
    TransactionSorting["TRANSACTION_AMOUNT"] = "TRANSACTION_AMOUNT";
    TransactionSorting["CHARGER_ID"] = "CHARGER_ID";
})(TransactionSorting = exports.TransactionSorting || (exports.TransactionSorting = {}));
