var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/**
 * Custom Error subclass that adds status and statusText from the fetch response
 */
var ApiError = /** @class */ (function (_super) {
    __extends(ApiError, _super);
    function ApiError(message, status, statusText, code, detail, url, durationMs) {
        var _this = _super.call(this, message) || this;
        _this.name = "ApiError";
        _this.status = status;
        _this.statusText = statusText;
        _this.code = code;
        _this.detail = detail;
        _this.url = url;
        _this.duration = "".concat(durationMs, "ms");
        return _this;
    }
    return ApiError;
}(Error));
export { ApiError };
/**
 * Custom Error subclass for when missing authn/authz.
 */
var LoginRequiredError = /** @class */ (function (_super) {
    __extends(LoginRequiredError, _super);
    function LoginRequiredError(message, url, code, detail) {
        var _this = _super.call(this, message) || this;
        _this.name = "LoginRequiredError";
        _this.url = url;
        _this.code = code;
        _this.detail = detail;
        return _this;
    }
    return LoginRequiredError;
}(Error));
export { LoginRequiredError };
/*
 * A set of helpers for fetch, which take a url, a method and an args object,
 * do an authenticated fetch, parses the result as json, and throws error on failures.
 *
 * Callbacks is an object containing callbacks including:
 * - `getToken` and `onGetTokenFailure` from falkon_auth:
 *    const { getToken, onGetTokenFailure } = useFalkonAuth();
 * - and optionally `onBeginAsyncTaskRetries` to be informed when an async task
 *    202 response has been received and the client has begun polling for a response.
 *
 * Can be used with async/await, eg:
 * await apiPost(`/api/create/`, values, { getToken, onGetTokenFailure })
 *
 * Or can be paired with useAsync from react-async-hooks, eg:
 * const { loading, error, result } = useAsync(apiFetch, [`/api/hello_world`, {method: 'GET', { getToken }}]);
 */
function apiFetchRaw(url, _a) {
    var method = _a.method, body = _a.body, formData = _a.formData, callbacks = _a.callbacks;
    return __awaiter(this, void 0, void 0, function () {
        var headers, token, e_1, error, json, bodyPayload;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    headers = {};
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, callbacks.getToken()];
                case 2:
                    token = _b.sent();
                    headers.Authorization = "Bearer ".concat(token);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    error = e_1.error;
                    // getToken can raise these error types when the access token has expired.
                    // https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/2
                    if ((error === "login_required" || error === "consent_required") &&
                        !!callbacks.onGetTokenFailure) {
                        callbacks.onGetTokenFailure();
                        return [2 /*return*/, Promise.reject(new LoginRequiredError("Failed to get auth token.", url, error, null))];
                    }
                    throw e_1;
                case 4:
                    json = body ? JSON.stringify(body) : undefined;
                    bodyPayload = undefined;
                    if (json) {
                        headers["Content-Type"] = "application/json";
                        bodyPayload = json;
                    }
                    else if (formData) {
                        // Let `fetch` infer the content-type in this scenario.
                        bodyPayload = formData;
                    }
                    return [4 /*yield*/, fetch(url, {
                            headers: headers,
                            body: bodyPayload,
                            method: method,
                            // Override the default of "strict-origin-when-cross-origin" to allow for a full
                            // path+query string in the referrer as long as we are not downgrading security.
                            // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy#directives
                            // for what the various policies mean.
                            // Note that this is only changing the policy for these fetch calls, not the site
                            // as a whole.
                            referrerPolicy: "no-referrer-when-downgrade",
                        })];
                case 5: return [2 /*return*/, _b.sent()];
            }
        });
    });
}
/**
 * Returns the decoded response body if the given response is `ok`, else throws
 * an ApiError with the error message.
 */
function responseToBody(res, elapsedTime) {
    return __awaiter(this, void 0, void 0, function () {
        var errorMessage, errorDetail, errorCode, json, error_1, error, contentType;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!res.ok) return [3 /*break*/, 5];
                    errorMessage = "error status from ".concat(res.url, " - ").concat(res.status);
                    errorDetail = void 0;
                    errorCode = void 0;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, res.json()];
                case 2:
                    json = _a.sent();
                    if (json) {
                        errorCode = json.code;
                        if (typeof json.detail === "string") {
                            errorMessage = json.detail;
                            if (json.additional_data) {
                                errorDetail = json.additional_data;
                            }
                        }
                        else {
                            errorDetail = json.detail;
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 4:
                    error = new ApiError(errorMessage, res.status, res.statusText, errorCode, errorDetail, res.url, elapsedTime);
                    console.error(JSON.stringify(error));
                    throw error;
                case 5:
                    contentType = res.headers.get("Content-Type");
                    if (!(contentType && contentType.match(/application\/json/))) return [3 /*break*/, 7];
                    return [4 /*yield*/, res.json()];
                case 6: return [2 /*return*/, (_a.sent())];
                case 7: return [4 /*yield*/, res.text()];
                case 8: 
                // default to return body as string
                // without known content-type
                return [2 /*return*/, (_a.sent())];
            }
        });
    });
}
var RETRY_COUNT_LIMIT = 1;
var THRESHOLD_504_ALLOW_RETRY_MS = 10000;
var DEFAULT_DELAY_BEFORE_RETRY_MS = 5000;
export function apiFetch(url, args, retryCount) {
    if (retryCount === void 0) { retryCount = 0; }
    return __awaiter(this, void 0, void 0, function () {
        var _a, response, elapsedTime;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, apiFetchBase(url, args, retryCount)];
                case 1:
                    _a = _b.sent(), response = _a[0], elapsedTime = _a[1];
                    return [2 /*return*/, responseToBody(response, elapsedTime)];
            }
        });
    });
}
export function apiFetchBlob(url, args, retryCount) {
    if (retryCount === void 0) { retryCount = 0; }
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiFetchBase(url, args, retryCount)];
                case 1:
                    response = (_a.sent())[0];
                    return [4 /*yield*/, response.blob()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function apiFetchResponse(url, args, retryCount) {
    if (retryCount === void 0) { retryCount = 0; }
    return __awaiter(this, void 0, Promise, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiFetchBase(url, args, retryCount)];
                case 1:
                    response = (_a.sent())[0];
                    return [2 /*return*/, response];
            }
        });
    });
}
function apiFetchBase(url, args, retryCount) {
    var _a, _b;
    if (retryCount === void 0) { retryCount = 0; }
    return __awaiter(this, void 0, Promise, function () {
        var startRequestTime, callbacks, response, elapsedTime, taskStatusUrl, delaySeconds, _loop_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    startRequestTime = performance.now();
                    callbacks = args.callbacks;
                    return [4 /*yield*/, apiFetchRaw(url, args)];
                case 1:
                    response = _c.sent();
                    elapsedTime = performance.now() - startRequestTime;
                    if (!(response.status === 202)) return [3 /*break*/, 5];
                    taskStatusUrl = response.headers.get("Location");
                    delaySeconds = Number(response.headers.get("Retry-After"));
                    // If this does not have a Location or Retry-After header then the object was accepted.
                    if (!taskStatusUrl && !delaySeconds) {
                        return [2 /*return*/, [response, elapsedTime]];
                    }
                    // Inform callers of the retry loop being initiated.
                    (_a = callbacks.onBeginAsyncTaskRetries) === null || _a === void 0 ? void 0 : _a.call(callbacks);
                    _loop_1 = function () {
                        var newArgs, newUrl, delayMillis;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    newArgs = taskStatusUrl
                                        ? { method: "GET", callbacks: callbacks }
                                        : __assign(__assign({}, args), { method: "GET", callbacks: callbacks });
                                    newUrl = taskStatusUrl ? taskStatusUrl : url;
                                    delayMillis = Number.isNaN(delaySeconds)
                                        ? DEFAULT_DELAY_BEFORE_RETRY_MS
                                        : delaySeconds * 1000;
                                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, delayMillis); })];
                                case 1:
                                    _d.sent();
                                    return [4 /*yield*/, apiFetchRaw(newUrl, newArgs)];
                                case 2:
                                    response = _d.sent();
                                    if (!(response.status === 401)) return [3 /*break*/, 4];
                                    return [4 /*yield*/, apiFetchRaw(response.url, newArgs)];
                                case 3:
                                    response = _d.sent();
                                    _d.label = 4;
                                case 4:
                                    taskStatusUrl = (_b = response.headers.get("Location")) !== null && _b !== void 0 ? _b : newUrl;
                                    delaySeconds = Number(response.headers.get("Retry-After"));
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _c.label = 2;
                case 2:
                    if (!(response.status === 202 || response.status === 504)) return [3 /*break*/, 4];
                    return [5 /*yield**/, _loop_1()];
                case 3:
                    _c.sent();
                    return [3 /*break*/, 2];
                case 4: return [3 /*break*/, 6];
                case 5:
                    if (response.status === 504) {
                        if (retryCount < RETRY_COUNT_LIMIT &&
                            elapsedTime < THRESHOLD_504_ALLOW_RETRY_MS) {
                            console.warn("Retried 504 - ".concat(response.url));
                            return [2 /*return*/, apiFetchBase(url, args, retryCount + 1)];
                        }
                    }
                    _c.label = 6;
                case 6: return [2 /*return*/, [response, elapsedTime]];
            }
        });
    });
}
