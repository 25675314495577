import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Fetcher } from "../Auth0Fetcher";
import { process } from "../Process";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { Tab } from "@headlessui/react";
import { TabStyle } from "../Components/TabStyle";
import { OrganizationOverview } from "../Components/Tabs/OrganizationOverview";
import { Transactions } from "../Components/Tabs/Transactions";
import { Usage } from "../Components/Tabs/Usage";
import { Revenue } from "../Components/Tabs/Revenue";
import { BigCircle } from "../Components/Images";
import { useNavigate, useParams } from "react-router-dom";
import { NOCOrganizationControls } from "../Components/Tabs/NocOrgControls";
import { SiteSelection } from "../Components/Tabs/SiteSelection";
import { NocReports } from "../Components/Tabs/NocOrgReports";
import { NocOrgStats } from "../Components/Tabs/NocOrgStats";
import { NOCPaymentMismatches } from "../Components/Tabs/NocPaymentMismatches";
import { NocThirdPartyAppToken } from "../Components/Tabs/NocThirdPartyAppToken";
import { CustomerLoyaltyStats } from "../Components/Tabs/CustomerLoyaltyStats";
import { CustomerDashboardStats } from "../Components/Tabs/CustomerDashboardStats";
import { GlobalAlerts } from "../Components/Tabs/GlobalAlerts";
import { OutstandingIssues } from "../Components/Tabs/OutstandingIssues";
// Retrieves a greeting string representing the local time of the user.
function getTimeString() {
    var now = new Date();
    var hour = now.getHours();
    if (hour < 12) {
        return "Good morning";
    }
    else if (hour < 18) {
        return "Good afternoon";
    }
    else {
        return "Good evening";
    }
}
// The dashboard is the landing page of command console containing high-level stats.
export var Dashboard = function (_a) {
    var _b;
    var isNoc = _a.isNoc, organizationId = _a.organizationId;
    var _c = useAuth0(), user = _c.user, isLoading = _c.isLoading, isAuthenticated = _c.isAuthenticated, getAccessTokenSilently = _c.getAccessTokenSilently;
    var data = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var firstOnline = useSWRImmutable(isLoading || !isAuthenticated || organizationId == -1
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/firstOnline/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher, {
        refreshInterval: undefined,
    }).data;
    var activeSessions = data === null || data === void 0 ? void 0 : data.sites.map(function (site) { return site.activeSessions; }).reduce(function (a, s) { return a + s; }, 0);
    var navigate = useNavigate();
    var tab = useParams().tab;
    var tabIndex = tab ? parseInt(tab) : undefined;
    var allAlerts = useSWR(!isNoc || isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/alerts/list_all/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px] p-[32px]" },
        React.createElement("label", { className: "text-white font-N4 mt-[32px]" }, "".concat(getTimeString(), ", ").concat((_b = user === null || user === void 0 ? void 0 : user.given_name) !== null && _b !== void 0 ? _b : user === null || user === void 0 ? void 0 : user.name, "!")),
        React.createElement("div", { className: "flex flex-row items-center gap-x-[12px]" },
            React.createElement("label", { className: "font-N6 text-black-200 mb-[8px]" }, "All Stations"),
            BigCircle,
            React.createElement("label", { className: "font-N8 text-black-600" }, "".concat(activeSessions, " Active Session").concat(activeSessions != 1 ? "s" : ""))),
        data && (React.createElement("div", { className: "mt-[32px]" },
            React.createElement(Tab.Group, { selectedIndex: tabIndex, onChange: function (index) { return navigate("/".concat(index)); } },
                React.createElement(Tab.List, { className: "flex flex-row flex-wrap gap-x-[16px] gap-y-[16px] mb-[16px]" },
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Overview"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Revenue"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Transactions"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Usage"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Dashboard Stats"),
                    React.createElement(Tab, { className: function (_a) {
                            var selected = _a.selected;
                            return TabStyle(selected);
                        } }, "Loyalty Stats"),
                    isNoc && (React.createElement(React.Fragment, null,
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "NOC Controls"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Site Selection"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Reports"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "API Token"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Payment Mismatches"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Statistics"),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return "".concat(TabStyle(selected)).concat(!selected &&
                                    allAlerts != null &&
                                    allAlerts.activeAlerts.length > 0
                                    ? " !text-red"
                                    : "");
                            } }, "Global Alerts".concat(allAlerts != null && allAlerts.activeAlerts.length > 0
                            ? " (".concat(allAlerts.activeAlerts.length, ")")
                            : "")),
                        React.createElement(Tab, { className: function (_a) {
                                var selected = _a.selected;
                                return TabStyle(selected);
                            } }, "Outstanding Issues")))),
                React.createElement(Tab.Panels, { className: "mt-[64px]" },
                    React.createElement(Tab.Panel, null,
                        React.createElement(OrganizationOverview, { organizationId: organizationId, siteList: data })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(Revenue, { resourceUrl: "".concat(process.env.CORE_SVC_URL, "/transactions/revenue/org/").concat(organizationId), feeResourceUrl: "".concat(process.env.CORE_SVC_URL, "/transactions/fees/org/").concat(organizationId), earliestTransaction: firstOnline !== null && firstOnline !== void 0 ? firstOnline : new Date() })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(Transactions, { resourceUrlBase: "".concat(process.env.CORE_SVC_URL, "/transactions/org/").concat(organizationId), earliestTransaction: firstOnline !== null && firstOnline !== void 0 ? firstOnline : new Date(), isNoc: isNoc })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(Usage, { resourceUrl: "".concat(process.env.CORE_SVC_URL, "/site/org/").concat(organizationId, "/stats"), startDate: firstOnline !== null && firstOnline !== void 0 ? firstOnline : new Date() })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(CustomerDashboardStats, { organizationId: organizationId, isNoc: isNoc })),
                    React.createElement(Tab.Panel, null,
                        React.createElement(CustomerLoyaltyStats, { organizationId: organizationId, isNoc: isNoc })),
                    isNoc && (React.createElement(React.Fragment, null,
                        React.createElement(Tab.Panel, null,
                            React.createElement(NOCOrganizationControls, null)),
                        React.createElement(Tab.Panel, null,
                            React.createElement(SiteSelection, null)),
                        React.createElement(Tab.Panel, null,
                            React.createElement(NocReports, null)),
                        React.createElement(Tab.Panel, null,
                            React.createElement(NocThirdPartyAppToken, null)),
                        React.createElement(Tab.Panel, null,
                            React.createElement(NOCPaymentMismatches, { earliestTransaction: firstOnline !== null && firstOnline !== void 0 ? firstOnline : new Date() })),
                        React.createElement(Tab.Panel, null,
                            React.createElement(NocOrgStats, { organizationId: organizationId })),
                        React.createElement(Tab.Panel, null,
                            React.createElement(GlobalAlerts, { alerts: allAlerts })),
                        React.createElement(Tab.Panel, null,
                            React.createElement(OutstandingIssues, { organizationId: organizationId }))))))))));
};
