import React from "react";
import { StaticTable } from "../Table";
export var AlertsTable = function (_a) {
    var alerts = _a.alerts, teams = _a.teams;
    var alertColumns = [
        {
            header: "Message",
            accessorFn: function (r) { return r.message; },
        },
        {
            header: "Status",
            accessorFn: function (r) { return r.status; },
        },
        {
            header: "Acknowledged",
            accessorFn: function (r) { return (r.acknowledged ? "Yes" : "No"); },
        },
        {
            header: "Time Created",
            accessorFn: function (r) { return new Date(r.createdAt).toLocaleString(); },
        },
        {
            header: "Owner",
            accessorFn: function (r) { return r.owner; },
        },
        {
            header: "Priority",
            accessorFn: function (r) { return r.priority; },
        },
        {
            header: "Team",
            accessorFn: function (r) { var _a, _b; return (_b = (_a = teams.find(function (t) { return t.id == r.ownerTeamId; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "None"; },
        },
    ];
    var openOpsgenieAlert = function (alertId) {
        window.open("https://electric-era.app.opsgenie.com/alert/detail/".concat(alertId, "/details"), "_blank");
    };
    return (React.createElement(StaticTable, { data: alerts, columnDefinitions: alertColumns, emptyMessage: "No active high priority alerts.", onClickRow: function (r) { return openOpsgenieAlert(r.id); } }));
};
