import React from "react";
import useSWR from "swr";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { useAuth0 } from "@auth0/auth0-react";
import { StaticTable } from "../Table";
import { charger } from "ee-proto-interfaces";
var SiteUnavailableChargers = function (_a) {
    var _b, _c;
    var site = _a.site;
    var _d = useAuth0(), isAuthenticated = _d.isAuthenticated, isLoading = _d.isLoading, getAccessTokenSilently = _d.getAccessTokenSilently;
    var hardwareTelemetry = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/telemetry/").concat(site.id),
            getAccessTokenSilently,
        ], Auth0Fetcher, {
        refreshInterval: 1000,
    }).data;
    var chargers = hardwareTelemetry === null || hardwareTelemetry === void 0 ? void 0 : hardwareTelemetry.chargers;
    var unavailableChargers = chargers === null || chargers === void 0 ? void 0 : chargers.filter(function (ch) {
        var _a;
        var relevantUnavailableReason = (_a = ch.unavailableReasons) === null || _a === void 0 ? void 0 : _a.filter(function (unavailableReason) {
            return unavailableReason !== charger.UnavailableReason.NONE;
        }); // using "charger" as a module name (charger.UnavailableReason) will potentially collide
        // with local vars named "charger" -- this is evil
        return relevantUnavailableReason && relevantUnavailableReason.length > 0;
    });
    var unavailableChargerColumns = [
        {
            header: "Charger ID",
            accessorFn: function (r) { return r.friendlyId; },
        },
        {
            header: "OCPP ID",
            accessorFn: function (r) { return r.ocppId; },
        },
        {
            header: "Unavailable Reason(s)",
            accessorFn: function (r) {
                var _a;
                var reasons = (_a = r.unavailableReasons) !== null && _a !== void 0 ? _a : [];
                var reasonsStr = reasons.map(function (reason) { return charger.UnavailableReason[reason]; });
                return reasonsStr.join(", ");
            },
        },
    ];
    return (((_b = unavailableChargers === null || unavailableChargers === void 0 ? void 0 : unavailableChargers.length) !== null && _b !== void 0 ? _b : 0) > 0 && (React.createElement("div", { className: "my-8" },
        React.createElement("label", { className: "font-N6 text-white" }, "".concat(site.name).concat(((_c = unavailableChargers === null || unavailableChargers === void 0 ? void 0 : unavailableChargers.length) !== null && _c !== void 0 ? _c : 0) > 0
            ? " (".concat(unavailableChargers === null || unavailableChargers === void 0 ? void 0 : unavailableChargers.length, " unavailable)")
            : "")),
        React.createElement(StaticTable, { data: unavailableChargers !== null && unavailableChargers !== void 0 ? unavailableChargers : [], columnDefinitions: unavailableChargerColumns, emptyMessage: "All chargers available!" }))));
};
export var OutstandingIssues = function (_a) {
    var _b, _c;
    var organizationId = _a.organizationId;
    var _d = useAuth0(), isAuthenticated = _d.isAuthenticated, isLoading = _d.isLoading, getAccessTokenSilently = _d.getAccessTokenSilently;
    var siteList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var now = new Date();
    return (React.createElement("div", { className: "flex flex-col gap-y-4" },
        React.createElement("div", null,
            React.createElement("label", { className: "font-N5 text-white" }, " Unavailable Chargers "), (_c = (_b = siteList === null || siteList === void 0 ? void 0 : siteList.sites) === null || _b === void 0 ? void 0 : _b.filter(function (site) {
            return site.active &&
                (!site.expectedDateOnline || now >= site.expectedDateOnline);
        })) === null || _c === void 0 ? void 0 :
            _c.map(function (site) { return (React.createElement(SiteUnavailableChargers, { key: site.id, site: site })); }))));
};
