import React from "react";
import TextTransition, { presets } from "react-text-transition";
import { HelpTooltip } from "./HelpTooltip";
import { PencilIcon } from "@heroicons/react/24/outline";
export var SingleStat = function (_a) {
    var title = _a.title, value = _a.value, helpText = _a.helpText, helpDetails = _a.helpDetails, center = _a.center, large = _a.large, className = _a.className, reverse = _a.reverse, titleClassName = _a.titleClassName, valueClassName = _a.valueClassName, monospace = _a.monospace, editFunction = _a.editFunction, editing = _a.editing;
    var titleSize = large ? "font-N7" : "font-N8p5";
    var valueSize = large ? "font-N6" : "font-N7";
    return (React.createElement("div", { className: "flex ".concat(reverse ? "flex-col-reverse" : "flex-col", " gap-y-[8px] ").concat(center ? "items-center" : "", " ").concat(className !== null && className !== void 0 ? className : "") },
        React.createElement("div", { className: "flex flex-row gap-x-2" },
            React.createElement(TextTransition, { className: "".concat(titleSize, " text-black-400 whitespace-nowrap [&>*]:whitespace-nowrap ").concat(titleClassName !== null && titleClassName !== void 0 ? titleClassName : "", " ").concat(center ? "m-auto" : ""), springConfig: presets.stiff }, title),
            editFunction && !editing && (React.createElement(PencilIcon, { className: "w-[13px] h-[13px] cursor-pointer", onClick: editFunction })),
            helpText != null && (React.createElement(HelpTooltip, { text: helpText, small: true, additionalDetails: helpDetails }))),
        React.createElement(TextTransition, { className: "".concat(valueSize, " text-white whitespace-nowrap [&>*]:whitespace-nowrap ").concat(valueClassName !== null && valueClassName !== void 0 ? valueClassName : "", " ").concat(center ? "m-auto" : "", " ").concat(monospace ? "font-mono" : ""), springConfig: presets.stiff }, value)));
};
