var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useSWR from "swr";
import { AlertsTable } from "../Alerts/AlertsTable";
import { LoadingIcon } from "ee-tailwind-components";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { ViewAllAlertsButton } from "../Alerts/ViewAllAlertsButton";
import { AckAlertsButton } from "../Alerts/AckAlertsButton";
import { AlertsSuppressionsDisplay } from "../Alerts/AlertSuppressionsDisplay";
export var GlobalAlerts = function (_a) {
    var alerts = _a.alerts;
    var _b = useAuth0(), getAccessTokenSilently = _b.getAccessTokenSilently, isLoading = _b.isLoading, isAuthenticated = _b.isAuthenticated;
    var alertsSuppressions = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.NOC_SVC_URL, "/alerts/global_suppression/"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var createGlobalAlertSuppression = function (suppression) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/alerts/global_suppression/"), {
                            method: "POST",
                            body: JSON.stringify(suppression),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateGlobalAlertSuppression = function (suppression) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/alerts/global_suppression/").concat(suppression.id), {
                            method: "PATCH",
                            body: JSON.stringify(suppression),
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var deleteGlobalAlertSuppression = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.AUTH0_AUDIENCE,
                        },
                    })];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(process.env.NOC_SVC_URL, "/alerts/global_suppression/").concat(id), {
                            method: "DELETE",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "flex flex-col gap-y-4" },
        alerts == null && React.createElement("div", { className: "w-[32px] h-[32px]" }, LoadingIcon),
        alerts != null && (React.createElement(React.Fragment, null,
            React.createElement("label", { className: "font-N5 text-white" }, "Teams"),
            alerts.teams.map(function (t, i) { return (React.createElement("a", { className: "font-N7 text-black-400 hover:text-white text-clip whitespace-nowrap w-fit", rel: "noopener noreferrer", href: "https://electric-era.app.opsgenie.com/teams/dashboard/".concat(t.id, "/main"), target: "_blank", key: i }, t.name)); }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
            React.createElement("div", { className: "flex flex-row gap-x-4 items-center mt-8" },
                React.createElement("label", { className: "font-N5 ".concat(alerts.activeAlerts.length > 0 ? "text-red" : "text-white") }, "Global High Priority Open Alerts (".concat(alerts.activeAlerts.length, ")")),
                React.createElement(AckAlertsButton, { alerts: alerts.activeAlerts })),
            React.createElement(AlertsTable, { alerts: alerts.activeAlerts, teams: alerts.teams }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
            React.createElement(AlertsSuppressionsDisplay, { alertsSuppressions: alertsSuppressions !== null && alertsSuppressions !== void 0 ? alertsSuppressions : [], alertHistory: alerts.alertHistory, onCreateAlertSuppression: createGlobalAlertSuppression, onUpdateAlertSuppresion: updateGlobalAlertSuppression, onDeleteAlertSuppression: deleteGlobalAlertSuppression, title: "Global Alert Suppressions" }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] my-8" }),
            React.createElement("div", { className: "flex flex-row gap-x-4 items-center mt-8" },
                React.createElement("label", { className: "font-N5 text-white" }, "Recent Alert History"),
                React.createElement(ViewAllAlertsButton, { powernodeIds: alerts.powernodeIds })),
            React.createElement(AlertsTable, { alerts: alerts.alertHistory, teams: alerts.teams })))));
};
