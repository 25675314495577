import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { AlertSuppressionEditDialog } from "./AlertSuppressionEditDialog";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
export var AlertsSuppressionsDisplay = function (_a) {
    var alertsSuppressions = _a.alertsSuppressions, alertHistory = _a.alertHistory, onCreateAlertSuppression = _a.onCreateAlertSuppression, onUpdateAlertSuppresion = _a.onUpdateAlertSuppresion, onDeleteAlertSuppression = _a.onDeleteAlertSuppression, title = _a.title;
    var _b = useState(false), showEditDialog = _b[0], setShowEditDialog = _b[1];
    var _c = useState(), suppressionToEdit = _c[0], setSuppressionToEdit = _c[1];
    var closeEditDialog = function () { return setShowEditDialog(false); };
    var openEditDialog = function (suppressionToEdit) {
        setSuppressionToEdit(suppressionToEdit);
        setShowEditDialog(true);
    };
    return (React.createElement("div", { className: "flex flex-col gap-y-4" },
        React.createElement("label", { className: "font-N5 text-white'} mt-8" }, title),
        (alertsSuppressions !== null && alertsSuppressions !== void 0 ? alertsSuppressions : [])
            .sort(function (a1, a2) {
            return new Date(a2.until).getTime() - new Date(a1.until).getTime();
        })
            .map(function (s, i) {
            var active = new Date().getTime() < new Date(s.until).getTime();
            return (React.createElement("div", { className: "flex flex-row gap-x-4 items-center", key: i },
                React.createElement("label", { className: "font-N6p75 text-white" }, "".concat(active ? "ACTIVE" : "EXPIRED", " SUPPRESSION: ")),
                React.createElement("label", { className: "font-N6p75 text-white" }, "".concat(s.description, " (created by ").concat(s.operator, ", expire").concat(active ? "s" : "d", " ").concat(new Date(s.until).toLocaleString(), ")")),
                React.createElement("label", { className: "font-N7 text-black-400 hover:text-white cursor-pointer whitespace-nowrap w-fit", onClick: function () { return openEditDialog(s); } }, "Edit")));
        }),
        React.createElement(Dialog, { open: showEditDialog, onClose: closeEditDialog, className: "relative z-50" },
            React.createElement("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }),
            React.createElement("div", { className: "fixed inset-0 flex items-center justify-center p-4" },
                React.createElement(Dialog.Panel, { className: "mx-auto" },
                    React.createElement(AlertSuppressionEditDialog, { suppression: suppressionToEdit, onClose: closeEditDialog, onSubmit: suppressionToEdit
                            ? onUpdateAlertSuppresion
                            : onCreateAlertSuppression, onDelete: 
                        /* TODO: Consider using a single table approach for suppressions.
                         * More details in wave/libraries/ee-nestjs-schema-pn/src/entities/global-alert-suppression.entity.ts
                         *
                         * suppressionToEdit.id might be an ID into SiteAlertSuppression or an ID into
                         * GlobalAlertSuppression, depending on who the parent of this component is.
                         * Shouldn't be causing any problems since it is consistent within the parent page.
                         */
                        suppressionToEdit
                            ? function () { return onDeleteAlertSuppression(suppressionToEdit.id); }
                            : undefined, alertHistory: alertHistory })))),
        React.createElement(StyledButton, { text: "CREATE NEW", className: "mt-8", style: StyledButtonStyle.Green, onClick: function () { return openEditDialog(undefined); } })));
};
